@use '../variables/colors';
@import '../variables/colors';
/*
 * Onboarding
*/
.form-container {
  padding: 2em;
  border-radius: 1em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 60vw;
  align-items: center;
  margin: auto;
  background-color: $white;

  .minimal {
    width: 90px;
  }
  .normal {
    width: 180px;
  }
  .wide {
    width: 100%;
  }
}

.login-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 25vw;
}

.form-container .header {
  justify-content: center;
  text-align: center;
}

.form-container .logo {
  width: 220px;
}

form.onboarding-form mat-form-field.mat-form-field,
form.onboarding-form button.mat-button {
  margin-left: 0%;
  margin-right: 0%;
  width: 100%;
}
form.onboarding-form button.mat-button {
  margin-top: 2em;
}
div.two-fa-setup {
  * {
    text-align: center;
  }
  button.mat-button {
    width: 40%;
    margin: 5%;
  }
}
.reset-password-container {
  .buttons {
    display: flex;
    width: inherit;
  }

  form {
    width: 100%;
  }
}
// profile widget
.profile-widget {
  display: flex;
  align-items: center;
  // margin-bottom: 1rem;
  // margin-left: auto;
  // margin-right: 0;
  // padding: 2rem 0.66rem 0 0;

  .user-name {
    font-weight: bold;
    margin: 0 2em 0 0;
    font-size: 0.88em;
    margin-right: 1rem;
    margin-left: auto;
    cursor: pointer;
  }
  .user-thumbnail-container {
    display: none;
  }
}

// EDIT PROFILE PAGE
.user-profile-form {
  tabs-container {
    min-width: 50vh;
  }
  display: flex;
  // Personal info
  .user-profile-personal-info-form {
    .mat-form-field {
      min-width: 12rem;
      width: 30%;
    }
    button.success {
      display: block;
    }
  }

  .user-widget-security-container {
    padding: 1rem;
    align-items: center;
    column-gap: 3vw;
  }
  .my-drop-zone {
    border: dotted 3px $info;
    min-height: 5em;
    display: inline-block;
    padding: 1rem;
    width: 65%;
    vertical-align: top;
    margin-left: 1rem;
  }
  .avatar-container {
    width: 20%;
    display: inline-block;
  }
  .nv-file-over {
    border: dotted 3px red;
  }
}
