/*
 * GENERAL
*/

// margins
$margin-sm: 0.5em;
$margin-md: 1em;
$margin-lg: 2em;
$margin-xl: 3em;

$margin-relative-md: 10%;

// border radius
$border-radius-sm: 0.5em;
// border width
$border-width-sm: 1px;
$border-width-md: 2px;
$border-width-lg: 3px;

/*
 * MENU
*/
$menu-min-width: 189px;

/*
 * ONBOARDING
*/
$onboarding-text-width: 25em;

/*
 * GENERIC FORMS
*/

/*
 * Weekdays toggle
*/
$weekdays-toggle-button-size: 3em;

/*
 * DASHBOARD
*/
$dashboard-card-lg-width: 20vw;
$dashboard-card-width: 15vw;

/*
 * FORMS
*/
$form-field-small: 40px;
$form-field-small: 200px;
$form-field-wide: 500px;
