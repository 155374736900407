/* MUSEUM PLUS BUS SPECIFIC */
$mpb-primary-green: #67cc84;
$mpb-primary-red: #de5c64;

$mpb-complementary-green: #e9efeb;
$mpb-complementary-gold: #d8973c;
$mpb-complementary-blue: #273e47;

/* ALERT AND WARNING COLORS */
$danger: #ff4444;
$warning: #ffbb33;
$info: #33b5e5;
$success: #00c851;

$danger-hover: #cc0000;
$warning-hover: #ff8800;
$info-hover: #0099cc;
$success-hover: #007e33;

/* GRAY SCALE AND TEXT */
$black: #252733;
$white: #ffffff;
$gray: #9fa2b4;
$gray-accent: #f0f1f7;

$main-text: $black;
$secondary-text: $gray;

/* BADGE COLORS & CLASSES */
.near-deadline {
  background-color: $warning;
}
.after-deadline {
  background-color: $danger;
}
.near-deadline:hover {
  background-color: $warning-hover;
}
.after-deadline:hover {
  background-color: $danger-hover;
}
/*
 * THEME
*/
$background-color: $mpb-complementary-blue;
$subtle-text-color: $gray;

/*
 * DASHBOARD
*/

$edit-mode-toggle-btn-color: $info;

/*
 * Weekdays toggle
*/
$weekdays-toggle-text: #bab8b8;
$weekdays-toggle-text-active: #007e33;
$weekdays-toggle-border-color: #e5e5e5;

$scrollbar-color: $gray;
