html,
body {
  height: 100%;
}

// text
.bold {
  font-weight: bold;
}

// spacers
span.tab {
  margin-right: 1em;
}

span.h-tab {
  height: 1em;
  display: block;
}
span.h-tab-sm {
  height: 0.5em;
  display: block;
}
span.h-tab-lg {
  height: 2em;
  display: block;
}
span.h-tab-xl {
  height: 3em;
  display: block;
}

// disable text selection
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
div.mat-sort-header-content {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.scrollable {
  width: 100%;
  padding: 1em 0.5em;
}
.mg-l {
  margin-left: 1em !important;
}
.mg-l-sm {
  margin-left: 0.3em !important;
}
.mg-t {
  margin-top: 1em !important;
}
.mg-b {
  margin-bottom: 1em !important;
}
.float-right {
  float: right;
}
